import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/amplitude-provider.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/modals/auth/login-modal-v2.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/photo-preview/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/provider/app-provider.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/socket-provider.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/styles/globals.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
import(/* webpackMode: "eager" */ "/home/ubuntu/apps/web/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/CalSans-SemiBold.woff2\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}")