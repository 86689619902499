"use client";

import Image from "next/image";
import React from "react";

const NextImage = ({
  alt = "",
  blurUrl = false,
  vertical = true,
  ...props
}) => {
  const [src, setSrc] = React.useState(props.src);

  return (
    <Image
      {...props}
      alt={alt}
      src={src}
      blurDataURL={
        blurUrl
          ? "/placeholder-blur.svg"
          : vertical
          ? "/placeholder.svg"
          : "/placeholder-horizontal.svg"
      }
      decoding="async"
      onError={() => {
        if (vertical) {
          setSrc("/placeholder.svg");
        } else {
          setSrc("/placeholder-horizontal.svg");
        }
      }}
    />
  );
};

export default NextImage;
