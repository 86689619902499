"use client";

import useAppStore from "./app-store";
import { mergeCartAfterLogged } from "@/actions/ecom-cart-v2";
import { getClientId } from "@/lib/local/laka-local-storage";
import { Session } from "next-auth/core/types";
import { useEffect } from "react";

interface AppProviderProps {
  session: Session | null;
  accessToken: string | null;
}

const AppProvider: React.FC<AppProviderProps> = ({ session, accessToken }) => {
  const { setUser } = useAppStore();

  useEffect(() => {
    if (session?.user) {
      const clientId = getClientId();
      mergeCartAfterLogged(clientId);
    }
    setUser(session?.user ? { ...session?.user, accessToken } : null);
  }, [session]);
  return <></>;
};

export default AppProvider;
