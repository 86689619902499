import { User } from "next-auth";
import { create } from "zustand";

interface UserApp extends User {
  id: string;
  accessToken: string | null;
}
interface AppStore {
  user?: UserApp;
  setUser: (UserApp) => void;
}
const useAppStore = create<AppStore>((set) => ({
  setUser: (user) => set({ user: user }),
}));

export default useAppStore;
